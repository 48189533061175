.home {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

.row-input-wrapper {
  align-items: center;
  display: flex;
}

.row-input-wrapper-end {
  align-items: center;
  justify-content: flex-end;
  display: flex;
}
.input-wrapper-row-inputbox {
  width: 100px !important;
}
.row-input-wrapper button {
  margin-left: 8px;
}
.row-input-wrapper > div {
  width: 100%;
}
.card-table-title {
  background: var(--grey-bg-color);
  color: var(--text-color);
  padding: 16px;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}
.card-table-title-multi {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}
.card-table-title-multi p {
  flex: 1;
}
.categories-bar {
  margin: 0 16px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 45px;
}
.categories-bar-dark input::placeholder {
  color: #bbb;
}
.categories-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.categories-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}

.illustration-wrapper {
  flex: 1;
  overflow: hidden;
}
.illustration {
  width: 90%;
}

.side-label-dash {
  margin: 16px;
}

.notification {
  z-index: 1000;
  position: fixed;
  width: 500px;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.busy {
  z-index: 1000;
  position: fixed;
  background: var(--blue-color);
  width: 140px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 4px;
  border-radius: 0 0 20px 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.busy img {
  height: 23px;
  margin-right: 8px;
  width: auto;
}
.busy p {
  margin-bottom: 3px;
}
.network-text {
  font-weight: 500;
  margin: 5%;
  color: #888;
}

.main-section {
  flex-grow: 1;
  overflow: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

table {
  background-color: #fff;
}

table.root-table-dark {
  background-color: #232429;
}

.table-content {
  margin: 16px;
  overflow: auto;
  position: relative;
  padding: 1px;
  flex: 1;
  size: auto;
}

.table-th-sticky {
  position: sticky;
  top: -1px;
  z-index: 100;
  background: #fff;
}
.dark-table-fake .table-th-sticky {
  background: #222;
}

.card-custom {
  position: absolute;
  top: 1;
  left: 1;
  min-width: 99%;
}

.card-multi-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.new-component {
  border: solid 2px rgb(194, 194, 194);
  border: solid 2px rgb(219, 219, 219);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;

  transition: box-shadow 0.2s ease-in-out;
}

.new-component:hover {
  -webkit-box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.6);
  box-shadow: 0px 0px 6px 2px rgba(204, 204, 204, 0.6);
}

.add-svg-wrapper {
  height: 70px;
  width: 70px;
}

.select-wrapper {
  height: 50%;
  padding: 16px;
}

.buttons-wrapper-multi {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}

.min-new-component {
  min-height: 278px;
}
