.side-label {
  display: flex;
  font-size: 13px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.side-bar {
  background: var(--dark-bg-color);
  color: var(--text-color);
  width: 256px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.side-bar-logo-wrapper {
  width: 100%;
  padding: 24px;
}

.side-bar-logo-wrapper img {
  height: auto;
  width: 100%;
}

.sidebar-list-wrapper {
  margin-top: 40px;
}

.side-list-button {
  margin-bottom: 8px;
}

.side-label p {
  padding: 8px;
}

.side-list-active {
  font-weight: 500;
  background: var(--blue-selector);
  color: #fff;
  border-radius: 3px;
}

.side-bar-toggle {
  background: #282828;
  width: 14px;
  cursor: pointer;
  flex-shrink: 0;
}
.side-bar-toggle img {
  width: auto;
  height: 24px;
}

.user-display {
  padding: 8px;
  margin: 20px;
  background: #555;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.user-display button {
  padding: 6px 12px;
}
.user-svg-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  background: rgb(219, 219, 219);
  background: linear-gradient(
    0deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(255, 255, 255, 1) 62%
  );
}

.side-svg {
  height: 24px;
  width: auto;
}

.export-to-excell-button {
  width: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px;
  padding-left: 16px;
  background: #282828;
}

.export-to-excell-button img {
  height: 32px;
  width: auto;
  margin-right: 32px;
  margin-top: 4px;
}

.button-is-active {
  background: var(--blue-selector);
}

.flexed {
  flex: 1;
}
