.location-top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 0 !important;
}
.location-top-bar-dark input::placeholder {
  color: #bbb;
}
.location-top-bar-dark input:-ms-input-placeholder {
  color: #bbb;
}
.location-top-bar-dark input::-ms-input-placeholder {
  color: #bbb;
}

.input-wrapper {
  margin: 0 8px;
  width: 250px;
}

.location-bar {
  padding: 12px 0 0 16px;
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  min-height: 144px;
  height: 144px;
  transition: all 0.6s ease-in-out;
}

.location-bar-extended {
  min-height: 320px;
  height: 320px;
}

.location-button {
  padding: 16px;
  height: 110px;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: 12px;
  border-radius: 6px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  text-align: center;
}

.location-button svg {
  fill: var(--grey-bg-color) !important;
}

.inverted {
  background: var(--grey-bg-color) !important;
  color: var(--text-color) !important;
}

.inverted svg {
  fill: #fff !important;
}
